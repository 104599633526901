// export const appPreview = 'https://i.imghippo.com/files/WEFI5915nAo.webp';
// export const appStoreButton = 'https://i.imghippo.com/files/MUoQ3332mGo.webp';
// export const googlePlayButton = 'https://i.imghippo.com/files/rho7972PME.webp';
// export const iconAndNameWhite = 'https://i.imghippo.com/files/xOs6862xHY.webp';
// export const iconAndNameSlate = 'https://i.imghippo.com/files/zF1111Bqg.webp';
import appStoreButton from './app-store.webp';
import googlePlayButton from './google-play.webp';
import appPreview from './iphone-16-preview.webp';
import {
  default as iconAndNameSlate,
  default as iconAndNameWhite,
} from './logo-and-name-slate.webp';

export const donnellansIcon = 'https://i.ibb.co/92rMBWq/donnellans.webp';
export const hynesIcon = 'https://i.ibb.co/6Nttxvn/hynes.png';
export const smithsIcon = 'https://i.ibb.co/yBD3sFL/smiths.webp';
export const theRavenIcon = 'https://i.ibb.co/bs9pNCT/the-raven.webp';
export const theShamrockIcon = 'https://i.ibb.co/Cw4YXqf/the-shamrock.webp';

export {
  appPreview,
  appStoreButton,
  googlePlayButton,
  iconAndNameSlate,
  iconAndNameWhite,
};
